import * as React from "react"

import AboutPageContent from "../components/AboutPage/AboutPageContent"
import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutPage = () => (
  <Layout>
    <Seo title="About me" />
    <AboutPageContent />
  </Layout>
)

export default AboutPage
