import React from 'react';

import BackToHomepageRedirect from '../common/BackToHomepage/BackToHomepageRedirect';
import ComingSoon from '../common/ComingSoon/ComingSoon';
import './AboutPageContent.css';

const AboutPageContent = () => {
    return (
        <>
            <ComingSoon />
            <p className="subtitle-about-content center-item">About section is still under construction.</p>
            <BackToHomepageRedirect />
        </>
    )
};

export default AboutPageContent;